
*,
:after,
:before {box-sizing: border-box;}

a { color:#3cc8c8;}
a:hover {color:#288f8f;}

body {margin: 0px;padding: 0px;line-height: normal;font-weight: normal;font-size: 14px; font-family: "Rubik", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;}

.site-logo {width: 27px;margin-right: 4px;}

.site-logo-name {font-weight: bold;font-weight: 600;font-size: 28px;}

.logo-sub-name {color: #69deea;}

.top-header-section  {width:100%; display:block; margin-bottom:30px;}

.top-header-section .section-header-bg {position:relative;  width:100%; display:block; background-image:url(../img/project-signup-header.jpg); background-size:cover;background-position:center center;}

.top-header-section .section-header-bg::before {background:#0b1e2c; opacity: 0.7;content: ""; position: absolute; top:0; right:0; bottom:0; left: 0;pointer-events: none;z-index: 1;}

.top-header-section .section-header-bg .section-content-text {width:100%;display:block;padding:85px 10px 100px 10px;color:#ffffff;z-index:1;position:relative;text-align:center;}

.top-header-section .section-header-bg .section-content-text .section-logo {width:100%;display:block;text-align:center;}

.top-header-section .section-header-bg .section-content-text .section-logo img {width:auto;}

.top-header-section .section-header-bg .section-content-text .section-box-typography {width:100%;display:block;text-align:center;}

.top-header-section .section-header-bg .section-content-text .section-box-typography .samll-heading {width:100%; display:block; font-size:29px; font-weight:300;}

.top-header-section .section-header-bg .section-content-text .section-box-typography .main-heading {width:100%; display:block; font-size:48px; font-weight:700; margin-top:25px;}

.top-header-section .section-header-bg .section-content-text .section-box-typography .heading-text {width:100%; display:block; font-size:16px; letter-spacing:0.5em; text-transform: uppercase; margin-top:30px; font-family:"Open Sans";}

.full-container-mid {width:100%; display:block; margin-top:35px; margin-bottom:50px;}

.full-container-mid h2 {font-size:25px; font-weight:700; margin:0 0 15px 0; color:#f15a24; text-align:center;  font-family:"Rubik";}

.full-container-mid .from-mid {width:100%; margin-top:35px; }

.full-container-mid {width:100%; display:block; margin-top:30px;}

.full-container-mid .remove-shadow {box-shadow:none; padding:0px;}

.full-container-mid  .input-group {display:block;width:100%; margin-bottom:2px;}

.full-container-mid .input-group .field-label {width:100%; display:block; color:#000000; margin-bottom:7px; font-weight:500; font-size:14px; text-align:left;}

.full-container-mid .input-group .contactus-field-input {border-radius:5px; min-height:40px; padding:0px; font-size:14px; width:100%; display:block; text-align:left; border: 1px solid #ddd; color:#000000;}

.full-container-mid .input-group .contactus-field-input .MuiInput-underline {width:100%; display:block; font-size:14px; line-height:inherit;}

.full-container-mid .input-group .contactus-field-input .MuiInput-underline::before {border-bottom:none; display:none;}

.full-container-mid .input-group .contactus-field-input .MuiInput-input {width:100%; display:block; border-radius:1px; font-size:14px; border:none; height:inherit; color:#000000; padding:10px; box-sizing:border-box; font-family: "Rubik";}

.full-container-mid .input-field-button {background:linear-gradient(225deg,#F7951F,#F76C1C);color:#ffffff; font-size:14px; margin-top:10px;min-width:80px;}

.full-container-mid .multiple-select-control {width:100%; display:block;}

.full-container-mid .multiple-select-control .multiple-select-field {border-radius:5px; min-height:40px; padding:0px; font-size:13px; width:100%; display:block; text-align:left; border: 1px solid #ddd; color:#000000;}

.full-container-mid .multiple-select-control .multiple-select-field .MuiSelect-select {box-sizing: border-box; padding:10px 30px 11px 14px; font-size:14px; color:#000000; font-family: "Rubik"; }

.full-container-mid .multiple-select-control .MuiOutlinedInput-notchedOutline { border:none;}

.MuiPopover-root .MuiMenu-list .MuiButtonBase-root .MuiTypography-root {font-size:14px;  font-family: "Rubik";}

.footer-content {width:100%; display:block; margin-top:140px; background-color: #fff; border-top: 1px solid #e0e0e0; padding:0;}

.footer-content .footer-box-1 { width:33.33%; display:inline-block; vertical-align:middle;  padding:10px 10px; text-align:left;}

.footer-content .footer-box-1 .email-text {display:block; font-size:13px; color:#000000; margin-bottom:4px;}

.footer-content .footer-box-1 .email-text a {font-size:13px;}

.footer-content .footer-box-1 .copy-rights {display:block; font-size:12px; color:#000000;}

.footer-content .footer-box-2 { width:33.33%; display:inline-block; vertical-align:middle;  padding:10px 10px; text-align:center;}

.footer-content .footer-box-2 .footer-soc-icon { list-style: none; display: inline-block; margin:0; padding:0;}

.footer-content .footer-box-2 .footer-soc-icon li {display: inline-block; margin:3px 9px; vertical-align:middle;}

.footer-content .footer-box-2 .footer-soc-icon li a {color:#42443E; font-size:18px;}

.footer-content .footer-box-2 .footer-soc-icon li a:hover {opacity:0.5;}

.footer-content .footer-box-3 { width:33.33%; display:inline-block; vertical-align:middle; text-align:right;  padding:10px 10px;}

.heading-box {
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  position: relative;
  margin-top: 12px;
}

.heading-box .heading-label {
  position: absolute;
  top: -12px;
  left: 4px;
  background: #fff;
  padding: 0 4px 0 2px;
}

.heading-box .heading-content {
  display: block;
  width: 100%;
  min-height: 120px;
}

@media (max-width: 1600px) {
  .top-header-section .section-header-bg .section-content-text .section-box-typography .samll-heading {font-size:26px; }

  .top-header-section .section-header-bg .section-content-text .section-box-typography .main-heading {font-size:40px;}
}
@media (max-width: 1000px) {
  .top-header-section .section-header-bg .section-content-text .section-box-typography .main-heading {font-size:35px;}
}
@media (max-width: 900px) {
.footer-content .footer-box-1, .footer-content .footer-box-2, .footer-content .footer-box-3 { width:100%; text-align:center ;}
}